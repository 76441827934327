import {ArticleBlockType, IArticleBlockData, IArticleData} from "../../../../../shared/models/IArticleData";

/******************************************************************
 * ArticleUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ArticleUtils {

   static getBlockType(block: IArticleBlockData): ArticleBlockType | "undefined" {
       if (!block) {
           return null;
       }
       return (Object.keys(block)?.find(key => {
           return key !== '_id' && key !== 'hidden' && block[key] !== null && block[key] !== undefined;
       }) as ArticleBlockType) ?? "undefined";
   }

    static isHelpArticle(article: IArticleData): boolean {
        return article?.title?.startsWith("help.")
    }

}
