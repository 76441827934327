import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useServices} from "../../../hooks/useServices";
import {IEventData} from "../../../../../shared/models/IEventData";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Table} from "../../table/Table";
import {Infobox} from "../Infobox";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {useMobileStyle} from "../../../hooks/useMobileStyle";

/******************************************************************
 * JoinedEventsInfobox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function JoinedEventsInfobox() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, router, api, dict} = useServices()
    const {routeOwnerID, routeOwnerPath, isRouteOwnerAuthUser, routeOwnerNick} = useRouteOwner()
    const [isMobileMode] = useMobileStyle(rootRef, 500)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [joinedEvents, setJoinedEvents] = useState<IEventData[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadLastJoinedEvents()
    }, [routeOwnerID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadLastJoinedEvents() {
        const result = await api.event.getJoinedEvents(routeOwnerID)
        if (!result || result.length == 0) {
            setLoading(false)
            return
        }
        result.sort((a, b) => {
            const result = b.state.localeCompare(a.state)
            if (result == 0) {
                return b._id.localeCompare(a._id)
            }
            return result
        })
        setJoinedEvents(result)
        setLoading(false)
    }

    function columnsDefinition(): ITableColumnDefinition<IEventData>[] {
        return [{
            type: isMobileMode ? "state-compact" : "state",
            dataKey: "state",
            align: "center",
            size: isMobileMode ? "40px" : "100px"
        }, {
            dataKey: "name",
            sortKey: "name",
            type: "text-bold"
        }, {
            type: "image-circle",
            dataKey: "owner.avatar",
            size: "50px"
        }];
    }

    function gotoEvent(eventData: IEventData) {
        router.showRoute(FrontendRoute.EVENT(eventData.ownerType, eventData.owner.path, eventData.path))
    }

    function title(): string {
        if (joinedEvents?.length == 1) {
            return dict("infobox.joinedEvents.single.title")
        }
        if (joinedEvents?.length > 0) {
            return joinedEvents.length + " " + dict("infobox.joinedEvents.title")
        }
        return dict("infobox.joinedEvents.title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            hasData={joinedEvents?.length > 0}
            loading={loading}
            noDataInfo={dict(isRouteOwnerAuthUser
                ? "infobox.joinedEvents.empty.info.owner"
                : "infobox.joinedEvents.empty.info.guest").replaceAll("{NICK}", routeOwnerNick)}
            createButtonLabel={dict("infobox.joinedEvents.create.button.label")}
            createButtonOnClick={() => state.showArticle.setValue(dict("article.id.joinCreateEvent"))}
            linkButtonLabel={dict("infobox.joinedEvents.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.USER_EVENTS(routeOwnerPath))}
            helpButtonLabel={dict("infobox.joinedEvents.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.events"))}
            onRefElement={element => rootRef.current = element}>
            <ComponentInitializer isPropertyAvailable={!loading}>
                <Table<IEventData>
                    rowsData={joinedEvents?.slice(0, 5)}
                    showHeader={false}
                    onRowClick={gotoEvent}
                    columnsDefinition={columnsDefinition()}/>
            </ComponentInitializer>
        </Infobox>
    );

}
