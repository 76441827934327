import * as React from "react";
import {useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {SessionStateType} from "../../../../../shared/types/SessionStateType";
import {ContextMenuState} from "../elements/ContextMenuState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {useEventState} from "../../../hooks/useEventState";
import {useGroupState} from "../../../hooks/useGroupState";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {KeyController} from "../../keyboard/KeyController";
import {useSessionState} from "../../../hooks/useSessionState";

/******************************************************************
 * SessionStateSelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionStateSelector(props: {
    sessionData: ISessionData,
    compactStateInfo: boolean,
    preventShortcutAction?: boolean,
    onToggle?: (showMenu: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api} = useServices();
    const {eventState} = useEventState();
    const {sessionID} = useSessionState()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {hasTouch} = useDeviceInfo();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(false);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changeState(state: SessionStateType) {
        setIsBusy(true)
        await api.session.update(props.sessionData._id, {state: state})
        setIsBusy(false)
    }

    function state() {
        return props.sessionData?.state
    }

    function enabled() {
        return (isRouteOwnerAuthUser || isGroupAdmin) && eventState != "finished"
    }

    function enableKeyShortcuts() {
        if (hasTouch) return false
        if (!enabled()) return false
        if (props.sessionData?._id != sessionID) return false
        return true
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContextMenu
            type="state"
            state={state()}
            compactStateInfo={props.compactStateInfo}
            stopPropagation={true}
            isBusy={isBusy}
            onToggle={props.onToggle}
            enabled={enabled()}>
            <ContextMenuState
                state="upcoming"
                selected={props.sessionData?.state == "upcoming"}
                keyShortcut={enableKeyShortcuts() ? FrontendConfig.SHORTCUT_SESSION_STATE_UPCOMING : null}
                onClick={() => changeState("upcoming")}/>
            <ContextMenuDivider style="small"/>
            {FrontendConfig.SHOW_SESSION_READY_STATE && <>
                <ContextMenuState
                    state="ready"
                    selected={props.sessionData?.state == "ready"}
                    onClick={() => changeState("ready")}/>
                <ContextMenuDivider style="small"/>
            </>}
            {eventState == "live" && <>
                <ContextMenuState
                    state="running"
                    selected={props.sessionData?.state == "running"}
                    keyShortcut={enableKeyShortcuts() ? FrontendConfig.SHORTCUT_SESSION_STATE_OPEN : null}
                    onClick={() => changeState("running")}/>
                <ContextMenuDivider style="small"/>
            </>}
            <ContextMenuState
                state="finished"
                selected={props.sessionData?.state == "finished"}
                keyShortcut={enableKeyShortcuts() ? FrontendConfig.SHORTCUT_SESSION_STATE_FINISHED : null}
                onClick={() => changeState("finished")}/>
        </ContextMenu>
        <KeyController
            enabled={enableKeyShortcuts() && !props.preventShortcutAction}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_UPCOMING}
            onKey={() => changeState("upcoming")}/>
        <KeyController
            enabled={enableKeyShortcuts() && !props.preventShortcutAction && eventState == "live"}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_OPEN}
            onKey={() => changeState("running")}/>
        <KeyController
            enabled={enableKeyShortcuts() && !props.preventShortcutAction}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_FINISHED}
            onKey={() => changeState("finished")}/>
    </>

}
