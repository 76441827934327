import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {IEventData} from "../../../../../shared/models/IEventData";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Table} from "../../table/Table";
import {Infobox} from "../Infobox";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * OwnedEventsInfobox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function OwnedEventsInfobox() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, router, api, dict} = useServices()
    const {groupID, groupPath, isGroupAdmin, groupName} = useGroupState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [events, setEvents] = useState<IEventData[]>()
    const [numEvents, setNumEvents] = useState<number>(0)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadOwnedEvents()
    }, [groupID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadOwnedEvents() {
        const result = await api.event.getOwnerEvents(groupID, "group")
        if (!result || result.length == 0) {
            setEvents(null)
            setLoading(false)
            return
        }
        result.sort((a, b) => {
            const result = b.state.localeCompare(a.state)
            if (result == 0) {
                return b._id.localeCompare(a._id)
            }
            return result
        })
        setNumEvents(result.length)
        setEvents(result.slice(0, 5))
        setLoading(false)
    }

    function columnsDefinition(): ITableColumnDefinition<IEventData>[] {
        return [{
            dataKey: "name",
            sortKey: "name",
            type: "text-bold"
        }, {
            type: "privacy",
            dataKey: "privacy",
            size: "20px",
        }, {
            type: "state",
            dataKey: "state",
            align: "center",
            size: "80px"
        }];
    }

    function gotoEvent(eventData: IEventData) {
        router.showRoute(FrontendRoute.EVENT(eventData.ownerType, eventData.owner.path, eventData.path))
    }

    function title(): string {
        if (numEvents == 1) {
            return dict("infobox.ownedEvents.single.title")
        }
        if (numEvents > 0) {
            return numEvents + " " + dict("infobox.ownedEvents.title")
        }
        return dict("infobox.ownedEvents.title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            hasData={events?.length > 0}
            loading={loading}
            noDataInfo={dict(isGroupAdmin
                ? "infobox.ownedEvents.empty.info.owner"
                : "infobox.ownedEvents.empty.info.guest").replaceAll("{GROUP}", groupName)}
            createButtonLabel={dict("infobox.ownedEvents.create.button.label")}
            createButtonOnClick={() => state.showCreateEvent.setValue(true)}
            linkButtonLabel={dict("infobox.ownedEvents.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.GROUP_EVENTS(groupPath))}
            helpButtonLabel={dict("infobox.ownedEvents.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.events"))}>
            <ComponentInitializer isPropertyAvailable={!loading}>
                <Table<IEventData>
                    rowsData={events?.slice(0, 5)}
                    showHeader={false}
                    onRowClick={gotoEvent}
                    columnsDefinition={columnsDefinition()}/>
            </ComponentInitializer>
        </Infobox>
    );

}
