import * as React from "react";
import {ICapiPingData} from "../../../../../../../shared/types/ICapiPingData";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {ICapiEndData} from "../../../../../../../shared/types/ICapiEndData";
import {ICapiStartData} from "../../../../../../../shared/types/ICapiStartData";
import {ICapiEnterData} from "../../../../../../../shared/types/ICapiEnterData";
import {ICapiTargetData} from "../../../../../../../shared/types/ICapiTargetData";
import {useServices} from "../../../../../hooks/useServices";

/******************************************************************
 * useSimulatorAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useSimulatorAPI() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {time} = useServices()

    /* ----------------------------------------------------------------
 	 * PING
 	 * --------------------------------------------------------------*/

    async function ping(gameID: string): Promise<ICapiPingData> {
        const response = await fetch(url(gameID, "/ping"), requestOptions("GET"))
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    /* ----------------------------------------------------------------
 	 * ENTER
 	 * --------------------------------------------------------------*/

    async function enter(
        gameID: string,
        capiPassword: string,
        nick: string,
        enterData: ICapiEnterData): Promise<any> {
        const data = getData(gameID, capiPassword, nick, enterData)
        const response = await fetch(url(gameID, "/enter"), requestOptions("POST", data))
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    /* ----------------------------------------------------------------
 	 * START
 	 * --------------------------------------------------------------*/

    async function start(
        gameID: string,
        capiPassword: string,
        nick: string,
        startData: ICapiStartData): Promise<any> {
        const data = getData(gameID, capiPassword, nick, startData)
        const response = await fetch(url(gameID, "/start"), requestOptions("POST", data))
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    /* ----------------------------------------------------------------
 	 * TARGET
 	 * --------------------------------------------------------------*/

    async function target(
        gameID: string,
        capiPassword: string,
        nick: string,
        targetData: ICapiTargetData): Promise<any> {
        const data = getData(gameID, capiPassword, nick, targetData)
        const response = await fetch(url(gameID, "/target"), requestOptions("POST", data))
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    /* ----------------------------------------------------------------
 	 * END
 	 * --------------------------------------------------------------*/

    async function end(
        gameID: string,
        capiPassword: string,
        nick: string,
        endData: ICapiEndData): Promise<any> {
        const data = getData(gameID, capiPassword, nick, endData)
        const response = await fetch(url(gameID, "/end"), requestOptions("POST", data))
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    /* ----------------------------------------------------------------
 	 * UTILS
 	 * --------------------------------------------------------------*/

    function requestOptions(methode: "GET" | "POST", data?: any): RequestInit {
        return {
            method: methode,
            body: data ? JSON.stringify(data) : null,
            headers: {
                'User-Agent': 'DRIFT',
                'Content-Type': 'application/json'
            },
            redirect: "follow"
        }
    }

    function url(gameID: string, route: string): string {
        if (!gameID.startsWith("/")) {
            gameID = "/" + gameID
        }
        return location.origin + gameID + route
    }

    function getData(gameID: string, capiPassword: string, nick: string, data: any): any {
        return {
            app_version: "1.4.19",
            game_id: gameID,
            user_id: "...",
            user_name: capiPassword + "@" + nick,
            time: TimeUtils.getCurrentIsoTimeString(time.date),
            data: data
        }
    }

    /* ----------------------------------------------------------------
 	 * API
 	 * --------------------------------------------------------------*/

    return {
        ping,
        enter,
        start,
        target,
        end
    };

}
