import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ContentLayout} from "../../layout/content/ContentLayout"
import {Table} from "../../table/Table"
import {IEventData} from "../../../../../shared/models/IEventData"
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute"
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition"
import {useSocketSignals} from "../../../hooks/useSocketSignals"
import {useServices} from "../../../hooks/useServices"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo"
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {GroupInfo} from "../../info/group/GroupInfo";
import {useMobileStyle} from "../../../hooks/useMobileStyle";

/******************************************************************
 * JoinedEventsList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function JoinedEventsList() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, router, dict} = useServices()
    const {serverEventUpdate} = useSocketSignals()
    const {routeOwnerID, isRouteOwnerAuthUser} = useRouteOwner()
    const [isMobileMode] = useMobileStyle(rootRef, 500)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isInitialized, setIsInitialized] = useState(false)
    const [eventsData, setEventsData] = useState<IEventData[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadEvents()
    }, [serverEventUpdate, routeOwnerID])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadEvents() {
        const result = await api.event.getJoinedEvents(routeOwnerID)
        result.sort((a, b) => b._id.localeCompare(a._id))
        setEventsData(result)
        setIsInitialized(true)
    }

    function showEvent(data: IEventData) {
        const eventData = eventsData.filter((eventData: IEventData) => eventData._id == data._id)[0]
        router.showRoute(FrontendRoute.EVENT(eventData.ownerType, eventData.owner.path, eventData.path))
    }

    function columnsDefinition(): ITableColumnDefinition<IEventData>[] {
        const columnsDefinition: ITableColumnDefinition<IEventData>[] = [{
            columnName: isMobileMode ? null : dict("event.column.state"),
            type: isMobileMode ? "state-compact" : "state",
            sortKey: "state",
            dataKey: "state",
            size: isMobileMode ? "40px" : "100px",
            align: "center"
        }, {
            type: "image",
            size: "50px",
            value: event => event.image ?? event.owner?.avatar
        }, {
            dataKey: "name",
            sortKey: "name",
            columnName: dict("event.column.name"),
            type: "text-bold",
            size: "4fr"
        }, {
            columnName: dict("event.column.owner"),
            type: "custom",
            size: "3fr",
            sortKey: "owner.name",
            customCell: (key, event) => {
                if (event.ownerType == "user") return <div key={key}></div>
                return <GroupInfo
                    key={key}
                    group={event.owner}
                    style="table-cell"
                    clickable={true}/>
            }
        }, {
            columnName: dict("event.column.date"),
            type: "text",
            size: "130px",
            sortKey: "_id",
            align: "right",
            value: event => {
                let formattedDate = TimeUtils.formatFromUntilDate(event.date, event.dateUntil)
                return formattedDate?.length > 0
                    ? formattedDate
                    : TimeUtils.formatFromUntilDate(TimeUtils.getDateFromObjectID(event._id).toISOString(), null)
            }
        }]
        if (isRouteOwnerAuthUser) {
            columnsDefinition.push({
                size: "50px",
                type: "privacy",
                dataKey: "privacy",
                sortKey: "privacy"
            })
        }
        return columnsDefinition
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={isInitialized} fullHeight={true}>
            <ContentLayout
                ref={rootRef}
                className="joined-events-list"
                alignSelf={eventsData?.length > 0 ? "start" : "stretch"}
                alignContent={eventsData?.length > 0 ? "start" : "center"}>
                {eventsData?.length > 0
                    ? <Table
                        style="standard"
                        rowsData={eventsData}
                        sortKey="state"
                        showFilter={eventsData?.length > 1}
                        onRowClick={showEvent}
                        visibleRows={25}
                        columnsDefinition={columnsDefinition()}/>
                    : <EmptyDataInfo text={isRouteOwnerAuthUser
                        ? dict("user.events.joined.empty")
                        : dict("user.events.jointd.empty.visitor")}/>}
            </ContentLayout>
        </ComponentInitializer>
    )

}
