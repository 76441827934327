import {FrontendBaseRouteType} from "../types/FrontendBaseRouteType";
import {FrontendRoute} from "../routes/FrontendRoute";
import {FrontendEventRouteType} from "../types/FrontendEventRouteType";
import {TrackOwnerType} from "../types/TrackOwnerType";
import {EventOwnerType} from "../types/EventOwnerType";
import {IFrontendEventRoute} from "../types/IFrontendEventRoute";
import {IFrontendTrackRoute} from "../types/IFrontendTrackRoute";
import {IFrontendGroupRoute} from "../types/IFrontendGroupRoute";
import {IFrontendUserRoute} from "../types/IFrontendUserRoute";

/******************************************************************
 * FrontendRouteUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendRouteUtils {

    /* ----------------------------------------------------------------
 	 * USER
 	 * --------------------------------------------------------------*/

    static parseUserRoute(route: string): IFrontendUserRoute {
        const routeParts = route?.split("/");
        if (!routeParts || routeParts.length == 0) {
            return null
        }
        if ("/" + routeParts[1] != FrontendRoute.USER_BASE) {
            return null;
        }
        if (!routeParts[2]) {
            return null;
        }
        return {
            route: route,
            userPath: "/" + routeParts[2],
            hasSubPath: !!routeParts[3],
            isDrivingSchool: routeParts[3] == "drivingschool",
            isTraining: routeParts[3] == "training",
            isGroups: routeParts[3] == "groups",
            isEvents: routeParts[3] == "events",
            isTracks: routeParts[3] == "tracks",
            isGarage: routeParts[3] == "garage"
        }
    }

    /* ----------------------------------------------------------------
 	 * EVENT
 	 * --------------------------------------------------------------*/

    static parseEventRoute(route: string): IFrontendEventRoute {
        if (!route) return null;
        const routeParts = route.split("/");
        if ("/" + routeParts[1] != FrontendRoute.EVENT_BASE) {
            return null;
        }
        if (!routeParts[2] || !routeParts[3] || !routeParts[4]) {
            return null;
        }
        let type: FrontendEventRouteType = "general";
        switch (routeParts[5]) {
            case "drivers":
                type = "drivers"
                break
            case "teams":
                type = "teams"
                break
            case "loaderboard":
                type = "leaderboard"
                break
            case "result":
                type = "result"
                break
            case "session":
                type = "session"
                break
            case "track":
                type = "track"
                break
        }
        return {
            route: route,
            ownerType: FrontendRouteUtils.expandOwnerType(routeParts[2]) as EventOwnerType,
            ownerPath: "/" + routeParts[3],
            eventPath: "/" + routeParts[4],
            trackPath: type == "track" ? "/" + routeParts[6] : null,
            sessionPath: type == "session" ? "/" + routeParts[6] : null,
            resultPath: type == "result" ? "/" + routeParts[6] : null,
            type: type
        }
    }

    /* ----------------------------------------------------------------
 	 * GROUP
 	 * --------------------------------------------------------------*/

    static parseGroupRoute(route: string): IFrontendGroupRoute {
        const routeParts = route?.split("/");
        if (!routeParts || routeParts.length == 0) {
            return null;
        }
        if ("/" + routeParts[1] != FrontendRoute.GROUP_BASE) {
            return null;
        }
        if (!routeParts[2]) {
            return null;
        }
        return {
            route: route,
            groupPath: "/" + routeParts[2],
            hasSubPath: !!routeParts[3],
            isEvents: routeParts[3] == "events",
            isTracks: routeParts[3] == "tracks",
            isMembers: routeParts[3] == "members"
        }
    }

    /* ----------------------------------------------------------------
 	 * TRACK
 	 * --------------------------------------------------------------*/

    static parseTrackRoute(route: string): IFrontendTrackRoute {
        if (!route) return null;
        const routeParts = route?.split("/");
        if ("/" + routeParts[1] != FrontendRoute.TRACK_BASE) {
            return null;
        }
        if (!routeParts[2] || !routeParts[3] || !routeParts[4]) {
            return null;
        }
        return {
            route: route,
            ownerType: FrontendRouteUtils.expandOwnerType(routeParts[2]) as TrackOwnerType,
            ownerPath: "/" + routeParts[3],
            trackPath: "/" + routeParts[4],
            filterPath: routeParts[5] ? "/" + routeParts[5] : null
        }
    }

    /* ----------------------------------------------------------------
 	 * BASE TYPE
 	 * --------------------------------------------------------------*/

    static getRouteType(route: string): FrontendBaseRouteType {
        const rootPath = "/" + route?.split("/")[1];
        switch (rootPath) {
            case FrontendRoute.ADMIN:
                return "admin"
            case FrontendRoute.USER_BASE:
                return "member"
            case FrontendRoute.EVENT_BASE:
                return "event"
            case FrontendRoute.GROUP_BASE:
                return "group"
            case FrontendRoute.TRACK_BASE:
                return "track"
            case FrontendRoute.GROUPS:
                return "groups"
            case FrontendRoute.EVENTS:
                return "events"
            case FrontendRoute.PLANS:
                return "plans"
            case FrontendRoute.HOME:
            case FrontendRoute.PADDLE:
            case FrontendRoute.ACADEMY:
            default:
                return "home"
        }
    }

    /* ----------------------------------------------------------------
 	 * SHORTEN / EXPAND
 	 * --------------------------------------------------------------*/

    public static expandOwnerType(ownerType: string): TrackOwnerType | EventOwnerType {
        switch (ownerType) {
            case "m":
                return "user"
            case "g":
                return "group"
        }
        return ownerType as TrackOwnerType | EventOwnerType
    }

    public static shortenOwnerType(ownerType: TrackOwnerType | EventOwnerType): string {
        switch (ownerType) {
            case "user":
                return "m"
            case "group":
                return "g"
        }
        return ownerType
    }

}
