import * as React from "react";

/******************************************************************
 * PendingIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PendingIcon(props: {
    scale?: number
}) {

    return (
        <div className="save-edit-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M0 8.94971C0 4.52971 3.58 0.949707 8 0.949707C12.42 0.949707 16 4.52971 16 8.94971C16 13.3697 12.42 16.9497 8 16.9497C3.58 16.9497 0 13.3697 0 8.94971ZM4.63199 4.33645C4.63199 4.67447 4.66415 5.00916 4.72676 5.33645C4.9261 6.37852 5.43417 7.34548 6.19622 8.10544L6.84718 8.75461H9.15279L9.80374 8.10546C10.5658 7.34549 11.0739 6.37851 11.2732 5.33643C11.3358 5.00914 11.368 4.67444 11.368 4.33643L10.368 4.33643L5.63199 4.33645L4.63199 4.33645ZM11.2732 12.563C11.3359 12.8902 11.368 13.2249 11.368 13.563L10.368 13.563L5.632 13.563L4.632 13.563C4.63201 13.225 4.66416 12.8903 4.72677 12.563C4.92612 11.5209 5.43419 10.5539 6.19626 9.79396L6.84721 9.1448H9.15282L9.80378 9.79397C10.5658 10.5539 11.0739 11.5209 11.2732 12.563ZM10.2508 12.563L5.74926 12.563C5.93342 11.7886 6.32955 11.0733 6.90239 10.502L7.26062 10.1448H8.73941L9.09764 10.5021C9.67048 11.0733 10.0666 11.7886 10.2508 12.563Z"
                      fill="white"/>
            </svg>
        </div>
    );

}
