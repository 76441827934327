import * as React from "react";
import {IArticleHeroBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Input} from "../../../form/elements/input/Input";
import {useServices} from "../../../../hooks/useServices";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import ReactMarkdown from "react-markdown";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useEffect, useRef, useState} from "react";
import {UploadableImage} from "../../../image/uploadable/UploadableImage";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {Picture} from "../../../image/Picture";
import {useElementSize} from "../../../../hooks/useElementSize";
import rehypeExternalLinks from "rehype-external-links";

/******************************************************************
 * ArticleHeroBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleHeroBlock(props: {
    articleID: MongoObjectIDType
    data: IArticleHeroBlockData
    editing: boolean
    language: LanguageType
    onChange: (data: IArticleHeroBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserHasPermission} = useAuthUser()
    const [isMobileStyle] = useMobileStyle(rootRef, 600)
    const [elementWidth] = useElementSize(rootRef)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [uploading, setUploading] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (elementWidth && rootRef.current) {
            rootRef.current.style.setProperty("--element-width", `${elementWidth}px`)
        }
    }, [elementWidth, rootRef.current])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateTitle(title: string, translate?: boolean): Promise<Response> {
        if (translate) {
            title = await api.translate.text(title, null, props.language)
        }
        props.data.title = title
        props.onChange(props.data)
        return null
    }

    async function updateSubTitle(subTitle: string, translate?: boolean): Promise<Response> {
        if (translate) {
            subTitle = await api.translate.text(subTitle, null, props.language)
        }
        props.data.subTitle = subTitle
        props.onChange(props.data)
        return null
    }

    async function updateImage(file: File) {
        setUploading(true)
        const fileData = await api.file.uploadImage({
            ownerID: props.articleID,
            ownerType: "article",
            fileID: props.data.file?._id,
            file: file
        })
        if (!fileData) {
            setUploading(false)
            return null
        }
        setUploading(false)
        props.data.file = fileData._id
        props.onChange(props.data)
        return null
    }

    async function updateAbstract(abstract: string, translate?: boolean): Promise<Response> {
        if (translate) {
            abstract = await api.translate.text(abstract, null, props.language)
        }
        props.data.abstract = abstract
        props.onChange(props.data)
        return null
    }

    async function updateAlt(alt: string, translate?: boolean): Promise<Response> {
        if (translate) {
            alt = await api.translate.text(alt, null, props.language)
        }
        props.data.alt = alt
        props.onChange(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="article-hero-block"
            gap="small">
            {props.editing
                ? <ContentLayout
                    columnTemplate={isMobileStyle ? null : "1fr 2fr"}
                    gap="small">
                    <ContentLayout gap="small">
                        <UploadableImage
                            onChange={updateImage}
                            progressing={uploading}
                            framing="rounded-4"
                            showUploadButton={props.editing}
                            editable={props.editing}
                            style="relative"
                            imageSrc={api.file.getImageURLByFile(props.data.file)}
                            fit="contain"
                            buttonLabel={dict("form.upload.article.select")}/>
                        <Input
                            type="text"
                            size="small"
                            label={dict("article.hero.alt")}
                            defaultValue={props.data.alt}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateAlt(props.data.alt, true)}
                            action={updateAlt}/>
                    </ContentLayout>
                    <ContentLayout gap="small">
                        <Input
                            type="textarea"
                            size="small"
                            label={dict("article.hero.title")}
                            defaultValue={props.data.title}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateTitle(props.data.title, true)}
                            action={updateTitle}/>
                        <Input
                            type="textarea"
                            size="small"
                            label={dict("article.hero.subTitle")}
                            defaultValue={props.data.subTitle}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateSubTitle(props.data.subTitle, true)}
                            action={updateSubTitle}/>
                        <Input
                            type="textarea"
                            size="small"
                            label={dict("article.hero.abstract")}
                            defaultValue={props.data.abstract}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateAbstract(props.data.abstract, true)}
                            action={updateAbstract}/>
                    </ContentLayout>
                </ContentLayout>
                : <>
                    <ContentLayout gap="tiny">
                        {props.data.title &&
                            <h1>
                                <ReactMarkdown>{props.data.title}</ReactMarkdown>
                            </h1>}
                        {props.data.subTitle &&
                            <h2>
                                <ReactMarkdown>{props.data.subTitle}</ReactMarkdown>
                            </h2>}
                    </ContentLayout>
                    {props.data.file &&
                        <Picture
                            file={props.data.file}
                            alt={props.data.alt}
                            framing="rounded-12"
                            allowMediaViewer={true}/>}
                    {props.data.abstract &&
                        <h3>
                            <ReactMarkdown
                                rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>
                                {props.data.abstract}
                            </ReactMarkdown>
                        </h3>}
                </>}
        </ContentLayout>
    );
}
