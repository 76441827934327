import {ISessionData} from "../models/ISessionData";
import {SharedConfig} from "../config/SharedConfig";
import {TimeUtils} from "./TimeUtils";

/******************************************************************
 * CapiSyncUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CapiSyncUtils {

    static computeSyncedLaps(session: ISessionData) {
        let laps = session.setup.laps
        switch (session.setup.mode) {
            case "lap":
            case "race":
                if (laps == 0 || session.setup.finishType == "duration") {
                    return SharedConfig.DRIFT_MAX_LAPS
                }
                switch (session.setup.finishType) {
                    case "laps":
                        laps = session.setup.laps + (session.setup.additionalSyncLaps ?? SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS)
                        if (laps > SharedConfig.DRIFT_MAX_LAPS) {
                            laps = SharedConfig.DRIFT_MAX_LAPS
                        }
                        break
                }
                break
            case "gymkhana":
                return 1
        }
        if (laps > SharedConfig.DRIFT_MAX_LAPS) {
            laps = SharedConfig.DRIFT_MAX_LAPS
        }
        return laps
    }

    static getStartTime(session: ISessionData) {
        if (session.setup.startTime == null) {
            return null
        }
        if (TimeUtils.isPast(session.setup.startTime)) {
            return null
        }
        return session.setup.startTime
    }

    static getWheels(session: ISessionData) {
        if (session.setup.tires?.length == 1) {
            return session.setup.tires[0]
        }
        return null
    }

    static getSetupMode(session: ISessionData) {
        if (session.setup.carSetup?.length == 1) {
            return session.setup.carSetup[0]
        }
        return null
    }

    static getTrackCondition(session: ISessionData) {
        if (session.setup.trackConditions?.length == 1) {
            return session.setup.trackConditions[0]
        }
        return null
    }

    static getStartDelay(session: ISessionData) {
        if (session.setup.timing == "sync") {
            return session.setup.startDelay ?? 0
        }
        return 0
    }
}
