import * as React from "react";
import {useEffect, useMemo} from "react";
import {AdminMain} from "./admin/AdminMain";
import {UserMain} from "./user/UserMain";
import {useRouteStates} from "../../hooks/useRouteStates";
import {EventMain} from "./event/EventMain";
import {TrackMain} from "./track/TrackMain";
import {GroupMain} from "./group/GroupMain";
import {useDeviceInfo} from "../../hooks/useDeviceInfo";
import {useServices} from "../../hooks/useServices";
import {ActionBar} from "../../ui/bar/ActionBar";
import {IconButton} from "../../ui/buttons/icon/IconButton";
import {MainMetaHeader} from "../../ui/header/MainMetaHeader";
import {HomeMain} from "./home/HomeMain";
import {ActionBarSpacer} from "../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../ui/utils/divider/Divider";
import {ScrollBox} from "../../ui/utils/scrollbox/ScrollBox";
import {GroupsMain} from "./groups/GroupsMain";
import {EventsMain} from "./events/EventsMain";
import {useMenuState} from "../../hooks/useMenuState";
import {CSSUtils} from "../../../utils/CSSUtils";
import {useAuthUser} from "../../hooks/useAuthUser";
import {PlansMain} from "./plans/PlansMain";

/******************************************************************
 * MainFrameSystem
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MainFrameSystem() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = React.useRef<HTMLElement>(null);
    const startTransformX = React.useRef<number>(undefined)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices()
    const {isMobile} = useDeviceInfo();
    const {authUserExists} = useAuthUser()
    const {showMobileMenu, swipingMobileMenuDeltaX, isSwipingMenu} = useMenuState();
    const {
        routeType,
        showSideBar,
        showHome,
        showAdmin,
        showUser,
        showEvent,
        showTrack,
        showGroup,
        showGroups,
        showEvents,
        showPlans
    } = useRouteStates();

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!swipingMobileMenuDeltaX || swipingMobileMenuDeltaX == 0) {
            ref.current.style.transform = null
            startTransformX.current = undefined
            return
        }
        let deltaX = swipingMobileMenuDeltaX
        if (startTransformX.current == undefined) {
            startTransformX.current = CSSUtils.getTransformXValueFromElement(ref.current)
        }
        const sidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--sidebar-width")
        const metabarWidth = authUserExists
            ? CSSUtils.getCSSValueFromElementByClass("metabar-system", "width")
            : 0
        const minX = -sidebarWidth - metabarWidth
        const maxX = 0;
        let x = startTransformX.current + deltaX
        if (x > maxX) x = maxX + (x - maxX) * 0.3
        if (x < minX) x = minX
        ref.current.style.transform = `translateX(${x}px)`
    }, [swipingMobileMenuDeltaX])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showMetaHeader(): boolean {
        if (routeType === "home") return false
        if (isMobile) return true
        return showSideBar
    }

    /* ----------------------------------------------------------------
 	 * MEMO (reenders only on specific state change)
 	 * --------------------------------------------------------------*/

    const scrollBoxMemo = useMemo(() => {
        return (
            <ScrollBox
                className="mainframe-system-content"
                scrollY={true}
                footerSpace={(showGroups || showEvents)
                    ? (isMobile ? "actionbar" : "none")
                    : "overscroll"}>
                {showHome && <HomeMain/>}
                {showGroups && <GroupsMain/>}
                {showEvents && <EventsMain/>}
                {showAdmin && <AdminMain/>}
                {showUser && <UserMain/>}
                {showEvent && <EventMain/>}
                {showTrack && <TrackMain/>}
                {showGroup && <GroupMain/>}
                {showPlans && <PlansMain/>}
            </ScrollBox>
        );
    }, [isMobile, showHome, showGroups, showEvents, showAdmin, showUser, showEvent, showTrack, showGroup, showPlans]);

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section
            className="mainframe-system"
            ref={ref}
            onClick={() => showMobileMenu && state.showMobileMenu.setValue(false)}
            data-show-sidebar={showSideBar}
            data-is-swiping={isSwipingMenu}
            data-has-meta-header={showMetaHeader()}
            data-show-mobile-menu={showMobileMenu && isMobile}>
            {showMetaHeader() &&
                <MainMetaHeader/>}
            {scrollBoxMemo}
            {isMobile &&
                <ActionBar
                    location="mobile-menu"
                    hide={showMobileMenu || isSwipingMenu}>
                    <ActionBarSpacer/>
                    <IconButton
                        type="sidebar"
                        scale={1.1}
                        size="small"
                        onClick={() => state.showMobileMenu.setValue(true)}/>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="prev"
                        scale={1.1}
                        size="small"
                        onClick={() => window.history.back()}/>
                    <ActionBarSpacer/>
                </ActionBar>}
        </section>
    );
}
