import * as React from "react";
import {useEffect, useState} from "react";
import {DriftClubLogo} from "../../logo/DriftClubLogo";
import {useRouteStates} from "../../hooks/useRouteStates";
import {useRouteOwner} from "../../hooks/useRouteOwner";
import {RouteLevelButton} from "./level/RouteLevelButton";
import {useGroupState} from "../../hooks/useGroupState";
import {useServices} from "../../hooks/useServices";
import {FrontendRoute} from "../../../../shared/routes/FrontendRoute";
import {useEventState} from "../../hooks/useEventState";
import {FrontendRouteUtils} from "../../../../shared/utils/FrontendRouteUtils";
import {useTrackState} from "../../hooks/useTrackState";
import {Divider} from "../utils/divider/Divider";
import {useAuthUser} from "../../hooks/useAuthUser";
import {UserInfo} from "../info/user/UserInfo";
import {ScrollBox} from "../utils/scrollbox/ScrollBox";
import {Badge} from "../info/badge/Badge";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {LabelButton} from "../buttons/label/LabelButton";

/******************************************************************
 * MainMetaHeader
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MainMetaHeader() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, router, state} = useServices()
    const {
        routeType,
        routePath,
    } = useRouteStates()
    const {groupName, groupPath} = useGroupState()
    const {authUserData} = useAuthUser()
    const {eventOwnerType, eventOwner, eventPath} = useEventState()
    const {trackOwnerType, trackOwner, trackPath} = useTrackState()
    const {routeOwnerNick, routeOwnerPath} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [firstLevelLabel, setFirstLevelLabel] = useState<string>()
    const [firstLevelRoute, setFirstLevelRoute] = useState<string>()
    const [secondLevelLabel, setSecondLevelLabel] = useState<string>()
    const [secondLevelRoute, setSecondLevelRoute] = useState<string>()
    const [thirdLevelLabel, setThirdLevelLabel] = useState<string>()
    const [thirdLevelRoute, setThirdLevelRoute] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateFirstLevel()
        updateSecondLevel()
        updateThirdLevel()
    }, [routeType, routePath])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateFirstLevel() {
        setFirstLevelLabel(groupName ?? routeOwnerNick)
        setFirstLevelRoute(groupName
            ? FrontendRoute.GROUP(groupPath)
            : FrontendRoute.USER(routeOwnerPath))
        switch (routeType) {
            case "home":
                setFirstLevelLabel(dict("home.title.welcome"))
                break;
            case "admin":
                setFirstLevelLabel(dict("admin.title"))
                break
        }
    }

    function updateSecondLevel() {
        setSecondLevelLabel(undefined)
        setSecondLevelRoute(undefined)
        switch (routeType) {
            case "home":
                break;
            case "groups":
                setFirstLevelLabel(dict("groups.title"))
                break;
            case "plans":
                setFirstLevelLabel(dict("plans.title"))
                break;
            case "events":
                setFirstLevelLabel(dict("events.title"))
                break;
            case "admin":
                const subRoute = routePath.split("/")[2];
                if (!subRoute) return
                setSecondLevelLabel(dict(`admin.${subRoute}.title`))
                break;
            case "member":
                const userRoute = FrontendRouteUtils.parseUserRoute(routePath)
                if (userRoute.isDrivingSchool) {
                    setSecondLevelLabel(dict("user.menu.drivingSchool"))
                }
                if (userRoute.isTraining) {
                    setSecondLevelLabel(dict("user.menu.training"))
                }
                if (userRoute.isEvents) {
                    setSecondLevelLabel(dict("user.menu.events"))
                }
                if (userRoute.isTracks) {
                    setSecondLevelLabel(dict("user.menu.tracks"))
                }
                if (userRoute.isGarage) {
                    setSecondLevelLabel(dict("user.menu.garage"))
                }
                if (userRoute.isGroups) {
                    setSecondLevelLabel(dict("user.menu.groups"))
                }
                break;
            case "event":
                switch (eventOwnerType) {
                    case "user":
                        setSecondLevelLabel(dict("user.menu.training"))
                        break;
                    case "group":
                        setSecondLevelLabel(dict("event.event.label"))
                        break;
                }
                setSecondLevelRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, eventPath))
                break;
            case "track":
                setSecondLevelLabel(dict("session.settings.track"))
                setSecondLevelRoute(FrontendRoute.TRACK(trackOwnerType, trackOwner.path, trackPath))
                break;
            case "group":
                const groupRoute = FrontendRouteUtils.parseGroupRoute(routePath)
                if (groupRoute.isEvents) {
                    setSecondLevelLabel(dict("group.menu.events"))
                }
                if (groupRoute.isTracks) {
                    setSecondLevelLabel(dict("group.menu.tracks"))
                }
                if (groupRoute.isMembers) {
                    setSecondLevelLabel(dict("group.menu.members"))
                }
                break;
        }
    }

    function updateThirdLevel() {
        setThirdLevelLabel(undefined)
        setThirdLevelRoute(undefined)
        switch (routeType) {
            case "home":
                break;
            case "admin":
                break;
            case "member":
                break;
            case "event":
                const eventRoute = FrontendRouteUtils.parseEventRoute(routePath)
                if (eventRoute.sessionPath) {
                    setThirdLevelLabel(dict("event.session.label"))
                }
                if (eventRoute.resultPath) {
                    setThirdLevelLabel(dict("event.result.label"))
                }
                if (eventRoute.trackPath) {
                    setThirdLevelLabel(dict("event.track.label"))
                }
                break;
            case "track":
                const trackRoute = FrontendRouteUtils.parseTrackRoute(routePath)
                if (trackRoute.filterPath) {
                    setThirdLevelLabel(dict("track.filter.title"))
                }
                break;
            case "group":
                break;
        }
    }

    async function showHome() {
        await router.showRoute(FrontendRoute.HOME)
        state.showMobileMenu.setValue(true)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="main-meta-header"
            data-server-enviroment={FrontendConfig.SERVER_ENVIRONMENT}
            data-use-gap={!authUserData || FrontendConfig.SERVER_ENVIRONMENT == "next"}>
            <ScrollBox scrollX={true}>
                <div className="main-meta-header-breadcrum">
                    <DriftClubLogo
                        minimized={true}
                        onClick={showHome}/>
                    <Divider style="main-meta-header-level"/>
                    <RouteLevelButton
                        label={firstLevelLabel}
                        route={routeType != "admin" ? firstLevelRoute : null}/>
                    {secondLevelLabel && <>
                        <Divider style="main-meta-header-level"/>
                        <RouteLevelButton
                            label={secondLevelLabel}
                            route={secondLevelRoute}/>
                    </>}
                    {thirdLevelLabel && <>
                        <Divider style="main-meta-header-level"/>
                        <RouteLevelButton
                            label={thirdLevelLabel}
                            route={thirdLevelRoute}/>
                    </>}
                </div>
            </ScrollBox>
            {FrontendConfig.SERVER_ENVIRONMENT == "next" &&
                <Badge
                    label={dict("server.enviroment." + FrontendConfig.SERVER_ENVIRONMENT)}
                    size="small"
                    color="yellow"/>}
            <div className="main-meta-header-authuser">
                {authUserData
                    ? <>
                        <Divider
                            style="main-meta-header-authuser"/>
                        <UserInfo
                            user={authUserData}
                            clickToShowProfile={true}
                            style={"avatar-only-small"}/>
                    </>
                    : <LabelButton
                        label={dict("home.nav.login")}
                        style="primary-small"
                        onClick={() => state.showLogin.setValue("login")}/>}
            </div>
        </div>
    );

}
