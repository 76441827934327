import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ContentLayout} from "../../layout/content/ContentLayout"
import {Table} from "../../table/Table"
import {IEventData} from "../../../../../shared/models/IEventData"
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute"
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition"
import {useSocketSignals} from "../../../hooks/useSocketSignals"
import {useServices} from "../../../hooks/useServices"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {useGroupState} from "../../../hooks/useGroupState"
import {useRouteStates} from "../../../hooks/useRouteStates"
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo";
import {Headline} from "../../text/headings/Headline";
import {LabelButton} from "../../buttons/label/LabelButton";
import {Spacer} from "../../utils/spacer/Spacer";
import {PrivacySelector} from "../../context/privacy/PrivacySelector";
import {EventStateSelector} from "../../context/state/EventStateSelector";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {useMobileStyle} from "../../../hooks/useMobileStyle";

/******************************************************************
 * OwnerEventsList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function OwnerEventsList() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, router, dict, state} = useServices()
    const {routeType} = useRouteStates()
    const {serverEventUpdate} = useSocketSignals()
    const {routeOwnerPath, routeOwnerID, isRouteOwnerAuthUser} = useRouteOwner()
    const {groupID, groupPath, isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(rootRef, 500)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isInitialized, setIsInitialized] = useState(false)
    const [eventsData, setEventsData] = useState<IEventData[]>(null)
    const [isTraining] = useState(routeType == "member")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadOwnerEvents()
    }, [serverEventUpdate, routeOwnerID, groupID, routeType])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadOwnerEvents() {
        let events: IEventData[]
        switch (routeType) {
            case "member":
                events = await api.event.getOwnerEvents(routeOwnerID, "user")
                break
            case "group":
                events = await api.event.getOwnerEvents(groupID, "group")
                break
        }
        if (!events || events.length == 0) {
            setIsInitialized(true)
            setEventsData(null)
            return
        }
        events.sort((a, b) => b._id.localeCompare(a._id))
        setEventsData(events)
        setIsInitialized(true)
    }

    function showEvent(data: IEventData) {
        const eventData = eventsData.filter((eventData: IEventData) => eventData._id == data._id)[0]
        switch (routeType) {
            case "member":
                router.showRoute(FrontendRoute.EVENT("user", routeOwnerPath, eventData.path))
                break
            case "group":
                router.showRoute(FrontendRoute.EVENT("group", groupPath, eventData.path))
                break
        }
    }

    function columnsDefinition() {
        const columnsDefinition: ITableColumnDefinition<IEventData>[] = [{
            columnName: isMobileMode ? null : dict("event.column.state"),
            type: "custom",
            sortKey: "state",
            size: isEvent() ? (isMobileMode ? "40px" : "100px") : null,
            align: "center",
            customCell: (key, event) => {
                return <ContentLayout key={key} justifyItems="center">
                    <EventStateSelector
                        eventData={event}
                        compact={isMobileMode}
                        updateMethode={loadOwnerEvents}/>
                </ContentLayout>
            }
        }, {
            type: "image",
            size: "50px",
            value: event => event.image ?? event.owner?.avatar
        }, {
            dataKey: "name",
            sortKey: "name",
            columnName: dict("event.column.name"),
            type: "text-bold",
            size: "3fr"
        }, {
            columnName: dict("event.column.date"),
            type: "text",
            size: isTraining ? null : "180px",
            sortKey: "_id",
            value: event => {
                let formattedDate = TimeUtils.formatFromUntilDate(event.date, event.dateUntil, dict)
                return formattedDate?.length > 0
                    ? formattedDate
                    : TimeUtils.formatFromUntilDate(
                        TimeUtils.getDateFromObjectID(event._id).toISOString()
                        , null
                        , dict)
            }
        }]
        columnsDefinition.push({
            size: "50px",
            type: "custom",
            sortKey: "privacy",
            customCell: (key, event) => {
                return <ContentLayout key={key} justifyItems="center">
                    <PrivacySelector eventData={event}/>
                </ContentLayout>
            }
        })
        return columnsDefinition
    }

    function emptyDataInfo(): string {
        if (isTraining) {
            if (isRouteOwnerAuthUser) {
                return dict("training.empty.owner.info")
            }
            return dict("training.empty.guest.info")
        }
        if (isGroupAdmin) {
            return dict("event.empty.owner.info")
        }
        return dict("event.empty.guest.info")
    }

    function isEvent(): boolean {
        return routeType == "group"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={isInitialized} fullHeight={true}>
            <ContentLayout
                ref={rootRef}
                className="owner-events-list"
                fullHeight={true}
                alignSelf={eventsData?.length > 0 ? "start" : "stretch"}
                alignContent={eventsData?.length > 0 ? "start" : "center"}>
                {eventsData?.length > 0
                    ? <>
                        <div>
                            <Spacer height="normal"/>
                            <Headline
                                text={dict(isTraining
                                    ? "user.menu.training"
                                    : "group.menu.events")}
                                align="center"
                                style="h1"/>
                            <Spacer height="large"/>
                        </div>
                        <Table
                            style="standard"
                            sortKey="state"
                            rowsData={eventsData}
                            showFilter={eventsData?.length > 1}
                            visibleRows={25}
                            onRowClick={showEvent}
                            columnsDefinition={columnsDefinition()}/>
                    </>
                    : <ContentLayout gap="none" justifyItems="center">
                        <EmptyDataInfo text={emptyDataInfo()}/>
                        <LabelButton
                            label={dict(isTraining
                                ? "training.empty.academy.button.label"
                                : "event.empty.academy.button.label")}
                            style="secondary"
                            icon="academy"
                            onClick={() => state.showAcademy.setValue(dict(isTraining
                                ? "academy.id.training"
                                : "academy.id.events"))}/>
                    </ContentLayout>
                }
            </ContentLayout>
        </ComponentInitializer>
    )

}
