import {LanguageType} from "../types/LanguageType";
import {FollowSessionGameIDRuleType} from "../types/FollowSessionGameIDRuleType";

/******************************************************************
 * SharedConfig
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SharedConfig {

    /* ----------------------------------------------------------------
 	 * CORE ERRORS
 	 * --------------------------------------------------------------*/

    static readonly NO_DB_AVAILABLE = "DRIFTCLUB HAS NO CONNECTION TO ITS DATABASE"

    /* ----------------------------------------------------------------
 	 * FILE
 	 * --------------------------------------------------------------*/

    static readonly FILE_UPLOAD_KEY = "file"

    /* ----------------------------------------------------------------
     * IMAGE
     * --------------------------------------------------------------*/

    static readonly DEFAULT_DEVICE_PIXEL_RATIO = 2
    static readonly DRIFTCLUB_LOGO_SRC = "/resources/images/driftclub-logo.png"
    static readonly DRIFTCLUB_LOGO_WHITE_SRC = "/resources/images/driftclub-logo-white.png"
    static readonly IMAGE_AVATAR_WIDTH = 500
    static readonly IMAGE_AVATAR_ASPECT_RATIO = 1
    static readonly IMAGE_EVENT_HERO_WIDTH = 1000
    static readonly IMAGE_EVENT_HERO_ASPECT_RATIO = 3 / 2
    static readonly IMAGE_TRACK_WIDTH = 1000
    static readonly IMAGE_TRACK_ASPECT_RATIO = 1
    static readonly IMAGE_CAR_WIDTH = 1000
    static readonly IMAGE_CAR_ASPECT_RATIO = 1
    static readonly IMAGE_STANDARD_WIDTH = 1000
    static readonly IMAGE_STANDARD_ASPECT_RATIO = 1

    /* ----------------------------------------------------------------
 	 * APP VERSION
 	 * --------------------------------------------------------------*/

    static readonly MIN_DRIFT_APP_VERSION = "1.4.19"

    /* ----------------------------------------------------------------
 	 * LANGUAGES
 	 * --------------------------------------------------------------*/

    static readonly LANGUAGES: LanguageType[] = ["de", "en"]
    static readonly DEFAULT_LANGUAGE: LanguageType = "en"

    /* ----------------------------------------------------------------
 	 * EVENT SETTINGS
 	 * --------------------------------------------------------------*/

    static readonly DEFAULT_EVENT_SESSION_START_TIME_DELAY = 5
    static readonly DEFAULT_EVENT_FOLLOW_SESSION_GAME_ID_RULE: FollowSessionGameIDRuleType = "keep"
    static readonly DEFAULT_EVENT_FOLLOW_SESSION_FINISH_OLD: boolean = true;

    /* ----------------------------------------------------------------
 	 * DRIFT
 	 * --------------------------------------------------------------*/

    static readonly DRIFT_MAX_LAPS = 100
    static readonly SESSION_MAX_LAPS = 10000
    static readonly DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS: number = 2;
    static readonly VALIDATE_LAPS: boolean = false

    /* ----------------------------------------------------------------
 	 * STINTS
 	 * --------------------------------------------------------------*/

    static readonly STINT_DRIVEN_TIME_AT_LAP_HISTORY_LENGTH = 5

    /* ----------------------------------------------------------------
 	 * MAX STRING LENGTHS
 	 * --------------------------------------------------------------*/

    static readonly MIN_MESSAGE_LENGTH = 10
    static readonly MAX_MESSAGE_LENGTH = 2500
    static readonly MAX_LOCATION_LENGTH = 500

}
