import {EventChildrenType} from "../types/EventChildrenType";
import {IEventData} from "../models/IEventData";
import {IUserData} from "../models/IUserData";
import {TimeUtils} from "./TimeUtils";

/******************************************************************
 * EventUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class EventUtils {

    static createEnrichedEventChildren(children: EventChildrenType): EventChildrenType {
        if (!children) {
            return null
        }
        if (children.sessions && children.results) {
            children.all = [...children?.sessions, ...children?.results]
            return children
        }
        if (children.sessions) {
            children.all = children?.sessions
            return children
        }
        if (children.results) {
            children.all = children?.results
            return children
        }
        return children
    }

    static isEntryFeeMessageNeededButNotDefined(eventData: IEventData): boolean {
        if (eventData?.drivers?.access == "only-registered-drivers-after-paid") {
            if (!eventData?.drivers?.entryFeeMessage) {
                return true
            }
        }
        return false
    }

    static isRequestAvailable(authUser: IUserData, eventData: IEventData, ignoreRequestForbidden?: boolean, ignoreMaxSeats?: boolean): boolean {
        if (!authUser || !eventData) return false
        if (eventData.ownerType == "user") return false
        if (eventData.state == "finished") return false
        if (!ignoreRequestForbidden && eventData.drivers?.requestsForbidden) return false
        if (!EventUtils.needParticipationRequest(eventData)) {
            return false
        }
        const requestingUsers = eventData?.drivers?.requesting ?? []
        const pendingUsers = eventData?.drivers?.pending ?? []
        const registeredUsers = eventData?.drivers?.registered ?? []
        const totalDrivers = requestingUsers.length + pendingUsers.length + registeredUsers.length
        const maxDrivers = eventData?.drivers?.maxDrivers ?? 0
        if (!ignoreMaxSeats && maxDrivers > 0 && totalDrivers >= maxDrivers) {
            return false
        }
        const forbiddenDrivers: IUserData[] = requestingUsers.concat(pendingUsers).concat(registeredUsers)
        return !forbiddenDrivers.find(user => user._id == authUser._id?.toString() ?? authUser.toString())
    }

    static needParticipationRequest(eventData: IEventData): boolean {
        switch (eventData?.drivers?.access) {
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return true
        }
        return false
    }

    static hasUserAlreadyRequestedParticipation(eventData: IEventData, userID: string): boolean {
        if (!eventData?.drivers?.requesting || !userID) {
            return false
        }
        return eventData?.drivers?.requesting
            ?.find(user => (user?._id?.toString() ?? user?.toString()) == userID)
    }

    static isParticipationRequestPending(eventData: IEventData, userID: string): boolean {
        if (!eventData?.drivers?.pending || !userID) {
            return false
        }
        return eventData?.drivers?.pending
            ?.find(user => (user?._id?.toString() ?? user?.toString()) == userID)
    }

    static isUserAlreadyRegistered(eventData: IEventData, userID: string): boolean {
        if (!eventData?.drivers?.registered || !userID) {
            return false
        }
        return eventData?.drivers?.registered
            ?.find(user => (user?._id?.toString() ?? user?.toString()) == userID)
    }

    static driverStatesInfo(eventData: IEventData, isGroupAdmin: boolean): string {
        switch (eventData?.drivers?.access) {
            case "any-member":
            case "only-group-members":
                return null
        }
        const requestingDrivers = eventData.drivers?.requesting?.length ?? "0"
        const pendingDrivers = eventData.drivers?.pending?.length ?? "0"
        const registeredDrivers = eventData.drivers?.registered?.length ?? "0"
        if (!isGroupAdmin) {
            return registeredDrivers.toString()
        }
        if (eventData?.state == "finished") {
            return `${registeredDrivers}`
        }
        switch (eventData?.drivers?.access) {
            case "only-registered-drivers":
                return `${registeredDrivers}`
            case "only-registered-drivers-after-approval":
                return `${requestingDrivers} / ${registeredDrivers}`
            case "only-registered-drivers-after-paid":
                return `${requestingDrivers} / ${pendingDrivers} / ${registeredDrivers}`
            default:
                return null
        }
    }

    static isLiveEvent(event: IEventData): boolean {
        return event.state == "live"
            && event.date
            && (TimeUtils.isToday(event.date)
                || TimeUtils.isBetweenNow(event.date, event.dateUntil)
                || TimeUtils.isToday(event.dateUntil))
    }

    static isUpcomingEvent(event: IEventData): boolean {
        return event.date
            && (TimeUtils.isFuture(event.date)
                || (TimeUtils.isToday(event.date) && event.state == "upcoming"))
    }

    static isLocalEvent(event: IEventData): boolean {
        const location = event.mapLocation ?? event.owner?.location
        return event.date && !!location?.longitude && !!location?.latitude
    }

    static isPermanentEvent(event: IEventData): boolean {
        return event.state == "live"
            && !event.date
    }

    static sortDateEvents(events: IEventData[]): IEventData[] {
        if (!events) return []
        return events.sort((a, b) => {
            const aDateDaysToToday = a.date ? TimeUtils.getDaysBetween(a.date) : Number.MAX_SAFE_INTEGER
            const aUntilDateDaysToToday = a.dateUntil ? TimeUtils.getDaysBetween(a.dateUntil) : Number.MAX_SAFE_INTEGER
            const bDateDaysToToday = b.date ? TimeUtils.getDaysBetween(b.date) : Number.MAX_SAFE_INTEGER
            const bUntilDateDaysToToday = b.dateUntil ? TimeUtils.getDaysBetween(b.dateUntil) : Number.MAX_SAFE_INTEGER
            const aNearestDaysToToday = Math.min(aDateDaysToToday, aUntilDateDaysToToday)
            const bNearestDaysToToday = Math.min(bDateDaysToToday, bUntilDateDaysToToday)
            return aNearestDaysToToday - bNearestDaysToToday
        })
    }


}
