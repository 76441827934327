import * as React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import {useEffect, useRef} from "react";
import {Icon} from "../../icons/Icon";
import {StringUtils} from "../../../../../shared/utils/StringUtils";
import rehypeExternalLinks from "rehype-external-links";

/******************************************************************
 * InfoText
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type InfoTextStyleType =
    "abstract" |
    "longText" |
    "info" |
    "warning" |
    "error" |
    "code"

export function InfoText(props: {
    text: string,
    className?: string,
    size?: "normal" | "small",
    width?: "normal" | "small",
    minHeight?: number,
    align?: "center" | "left",
    style?: InfoTextStyleType,
    showClickInfo?: boolean,
    onClick?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        rootRef.current.querySelectorAll('ol[start]').forEach((ol: HTMLOListElement) => {
            const startValue = ol.getAttribute('start') || '1';
            ol.style.setProperty('--start-value', (parseInt(startValue, 10) - 1).toString());
        });
    }, [rootRef.current, props.text]);

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <div
            ref={rootRef}
            className={"infotext" + (props.className ? " " + props.className : "")}
            style={{
                minHeight: props.minHeight,
                cursor: props.showClickInfo ? "pointer" : "auto"
            }}
            onClick={props.onClick}
            data-style={props.style ?? "info"}
            data-size={props.size ?? "normal"}
            data-width={props.width ?? "normal"}
            data-align={props.align ?? "left"}>
            {props.showClickInfo &&
                <Icon
                    className="infotext-clickinfo"
                    type="info"
                    scale={0.8}
                    iconColor="white"/>}
            <ReactMarkdown
                rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}
                remarkPlugins={[remarkGfm]}>
                {StringUtils.preprocessMarkdown(props.text)}
            </ReactMarkdown>
        </div>
    );

}
