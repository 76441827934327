import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useEventState} from "../../../hooks/useEventState";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {FrontendRouteUtils} from "../../../../../shared/utils/FrontendRouteUtils";
import {SessionStateSelector} from "../../context/state/SessionStateSelector";
import {SessionContextMenu} from "../../context/session/SessionContextMenu";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {FormUtils} from "../../../../utils/FormUtils";
import {EditableField} from "../../form/editable/EditableField";
import {useGroupState} from "../../../hooks/useGroupState";
import {Icon} from "../../icons/Icon";
import {IconType} from "../../icons/IconType";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {DateUtils} from "@webfruits/toolbox/dist/utils/DateUtils";
import {KeyController} from "../../keyboard/KeyController";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {ConfirmResetCustomElement} from "../../../systems/modals/session/reset/ConfirmResetCustomElement";

/******************************************************************
 * SessionButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionButton(props: {
    session: ISessionData
    isDragging: boolean
    folded?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state, router, api, time} = useServices()
    const {eventPath, eventState, eventOwnerType, eventOwner, eventIsFinished} = useEventState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {routePath} = useRouteStates()
    const {isGroupAdmin} = useGroupState()
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const inputRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isCurrentSession, setIsCurrentSession] = useState<boolean>();
    const [hover, setHover] = useState<boolean>(hasTouch);
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const [iconType, setIconType] = useState<IconType>();
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
    const [stateSelectorOpen, setStateSelectorOpen] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<string>(computeStartTime());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setStartTime(computeStartTime())
        let interval = window.setInterval(() => {
            setStartTime(computeStartTime())
        }, 60 * 1000)
        return () => clearInterval(interval)
    }, [props.session.setup.startTime])

    useEffect(() => {
        if (!routePath) return;
        const eventRoute = FrontendRouteUtils.parseEventRoute(routePath);
        setIsCurrentSession(eventRoute?.sessionPath == props.session.path)
    }, [routePath, props.session.path])

    useEffect(() => {
        switch (props.session.setup.mode) {
            case "lap":
                return setIconType("watch")
            case "race":
                return setIconType("chart")
            case "gymkhana":
                return setIconType("drift")
        }
    }, [props.session])

    useEffect(() => {
        if (contextMenuOpen == false && stateSelectorOpen == false) {
            setHover(hasTouch);
        }
    }, [contextMenuOpen, stateSelectorOpen])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoSession(e: React.MouseEvent) {
        if (isRenaming) return;
        switch (e.detail) {
            case 1:
                router.showRoute(FrontendRoute.EVENT_SESSION(eventOwnerType, eventOwner.path, eventPath, props.session.path));
                break;
            case 2:
                editSessionName();
                break;
        }
    }

    async function editSessionName() {
        if (!isRouteOwnerAuthUser && !isGroupAdmin) return;
        if (isRenaming) return;
        setIsRenaming(true);
        await PromisedDelay.wait(0.1);
        FormUtils.focusAndPositionCaretToEnd(inputRef.current);
    }

    async function updateName(value: string): Promise<Response> {
        return await api.session.update(props.session._id, {name: value});
    }

    function editSessionPath() {
        setHover(false);
        state.showEditSessionPath.setValue(props.session);
    }

    function computeStartTime(): string {
        const startTime = props.session.setup.startTime
        if (!startTime) return null
        if (TimeUtils.isPast(startTime, time.date)) return null
        const hoursLeft = TimeUtils.getHoursBetween(startTime)
        const date = new Date(startTime)
        if (TimeUtils.isToday(startTime, time.date)) {
            return DateUtils.getFormattedDayTime(date)
        }
        if (hoursLeft < 48) {
            return dict("session.button.starttime.hoursleft").replace("{HOURS}", hoursLeft.toString())
        }
        const daysLeft = TimeUtils.getDaysBetween(startTime)
        return dict("session.button.starttime.daysleft").replace("{DAYS}", daysLeft.toString())
    }

    function isTraining(): boolean {
        return eventOwnerType == "user"
    }

    function resetSession() {
        state.showConfirm.setValue({
            type: "resetSession",
            confirmed: true,
            customElement: <ConfirmResetCustomElement sessionData={props.session}/>,
            payload: {
                sessionName: props.session.name,
                sessionID: props.session._id
            }
        })
    }

    function deleteSession() {
        state.showConfirm.setValue({
            type: "deleteSession",
            confirmed: true,
            payload: {
                sessionName: props.session.name,
                sessionID: props.session._id
            }
        })
    }

    function canEdit(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    function enableShortcuts(): boolean {
        if (!canEdit()) return false
        if (hasTouch) return false
        if (isRenaming) return false
        if (!isCurrentSession) return false
        return true
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <div
            className="session-button"
            data-folded={props.folded && !isCurrentSession}
            data-hover={hover}
            data-renaming={isRenaming}
            data-is-owner={isRouteOwnerAuthUser || isGroupAdmin}
            data-show-state={!isTraining() && eventState != "finished"}
            data-dragging={props.isDragging}
            data-selected={isCurrentSession}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(hasTouch)}
            onClick={gotoSession}>
            <div className="session-button-label">
                <Icon type={iconType} scale={0.8}/>
                <EditableField
                    text={props.session.name}
                    action={updateName}
                    disabled={!isRenaming}
                    useEnterToSave={true}
                    ui="right"
                    onInputRef={(ref) => inputRef.current = ref.current}
                    onExit={() => setIsRenaming(false)}/>
            </div>
            {!isRenaming &&
                <div className="session-button-meta">
                    {startTime && (hasTouch || !canEdit() ? true : !hover) &&
                        <div
                            className="session-button-meta-starttime"
                            data-remove-padding={hasTouch && canEdit()}>
                            {startTime}
                        </div>}
                    {canEdit() && hover &&
                        <SessionContextMenu
                            onClicked={() => setHover(hasTouch)}
                            enableShortCuts={enableShortcuts()}
                            onRequestEditName={editSessionName}
                            onRequestEditPath={editSessionPath}
                            onToggle={setContextMenuOpen}
                            sessionData={props.session}/>}
                    {eventState != "finished" && !isTraining() &&
                        <SessionStateSelector
                            sessionData={props.session}
                            onToggle={setStateSelectorOpen}
                            compactStateInfo={true}/>}
                </div>}
        </div>
        <KeyController
            key={"path" + (props.session.path ?? "null")}
            enabled={enableShortcuts() && !eventIsFinished}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_GAMEID}
            onKey={editSessionPath}/>
        <KeyController
            enabled={enableShortcuts()}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_SETTINGS}
            onKey={() => state.showSessionSettings.setValue(true)}/>
        <KeyController
            key={"reset" + (props.session.setup.startTime ?? "null")}
            enabled={enableShortcuts() && !eventIsFinished}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_RESET}
            onKey={resetSession}/>
        <KeyController
            key={"follow" + (props.session.setup.startTime ?? "null")}
            enabled={enableShortcuts() && !eventIsFinished}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_ADD_FOLLOW_SESSION}
            onKey={() => state.showCreateFollowSession.setValue({originalSession: props.session})}/>
        <KeyController
            enabled={enableShortcuts()}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_DELETE}
            onKey={deleteSession}/>
    </>

}
