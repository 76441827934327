import * as React from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {LabelValueText} from "../../../ui/text/labelvalue/LabelValueText";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {ReleaseNotesList} from "../../../ui/list/releasenotes/ReleaseNotesList";

/******************************************************************
 * VersionModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function VersionModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            closeAction={() => state.showVersion.setValue(false)}>
            <Headline text={dict("version.info.title")} style="modal"/>
            <ContentLayout>
                {!FrontendConfig.PADDLE_ENABLED_PER_DEFAULT &&
                    <InfoText
                        text={dict("meta.version.info")}
                        style="warning"
                        size="small"/>}
                <ContentLayout columns={3}>
                    <LabelValueText
                        label={dict("meta.version.server")}
                        value={FrontendConfig.SERVER_VERSION}/>
                    <LabelValueText
                        label={dict("meta.version.frontend")}
                        value={FrontendConfig.FRONTEND_VERSION}/>
                    <LabelValueText
                        label={dict("meta.version.backend")}
                        value={FrontendConfig.BACKEND_VERSION}/>
                </ContentLayout>
                <ReleaseNotesList showFrame={true}/>
            </ContentLayout>
        </Modal>
    );

}
