import {IStintLapData} from "../types/IStintLapData"
import {LanguageType} from "../types/LanguageType";
import {StintUtils} from "./StintUtils";
import {CharUtils} from "./CharUtils";
import {MongoObjectIDType} from "../types/MongoObjectIDType";

/******************************************************************
 * TimeUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TimeUtils {

    static getDateFromObjectID(id: MongoObjectIDType) {
        if (!id) return undefined
        const timestampHex = id.substring(0, 8);
        const timestamp = parseInt(timestampHex, 16);
        return new Date(timestamp * 1000);
    }

    static calcTimeInSeconds(newerTime: string | Date, olderTime: string | Date): number {
        if (!newerTime || !olderTime) return undefined
        const newerData = newerTime instanceof Date ? newerTime : new Date(newerTime)
        const olderData = olderTime instanceof Date ? olderTime : new Date(olderTime)
        const diffInSeconds = (newerData.getTime() - olderData.getTime()) / 1000
        return parseFloat(diffInSeconds.toFixed(3))
    }

    static formatFromUntilDate(from: string, until: string, dict?: (key: string) => string): string {
        if (!from) return ""
        const date = from ? new Date(from) : null;
        const month = dict ? dict("date.month." + date.getMonth()) : (date.getMonth() + 1 + ".")
        if (!until || from == until) {
            return `${date.getDate()}. ${month} ${date.getFullYear()}`
        }
        const untilDate = until ? new Date(until) : null;
        const isSameYear = date && untilDate && date.getFullYear() === untilDate.getFullYear();
        const isSameMonth = date && untilDate && date.getMonth() === untilDate.getMonth() && isSameYear;
        const untilMonth = dict ? dict("date.month." + untilDate.getMonth()) : (untilDate.getMonth() + 1 + ".")
        if (isSameYear && isSameMonth) {
            return `${date.getDate()}.-${untilDate.getDate()}. ${month} ${date.getFullYear()}`
        }
        if (isSameYear) {
            return `${date.getDate()}. ${month} - ${untilDate.getDate()}. ${untilMonth} ${date.getFullYear()}`
        }
        return `${date.getDate()}. ${month} ${date.getFullYear()} - ${untilDate.getDate()}. ${untilMonth} ${untilDate.getFullYear()}`
    }

    static durationToSeconds(durationHHMMSS: string): number {
        if (!durationHHMMSS) return undefined
        const [hours, minutes, seconds] = durationHHMMSS.split(':').map(Number)
        return hours * 3600 + minutes * 60 + seconds
    }

    static hasDurationBeenReached(startTime: string, endTime: string, duration: string): boolean {
        if (!startTime || !endTime || !duration) return false
        const start = new Date(startTime).getTime()
        const end = new Date(endTime).getTime()
        const [hours, minutes, seconds] = duration.split(':').map(part => parseInt(part, 10))
        const durationInMs = (hours * 60 * 60 + minutes * 60 + seconds) * 1000
        return end - start >= durationInMs
    }

    static getBestLapTimeLap(lapsData: IStintLapData[]): IStintLapData {
        if (!lapsData) return null
        return lapsData.reduce((lowest: IStintLapData | null, current: IStintLapData) => {
            if (current.time != null
                && (lowest == null || current.time < lowest.time)
                && StintUtils.isLapValid(current)) {
                return current
            } else {
                return lowest
            }
        }, null)
    }

    static isBestLapTime(lapData: IStintLapData, lapsData: IStintLapData[]): boolean {
        if (!lapData || !lapsData || !StintUtils.isLapValid(lapData)) {
            return false
        }
        const bestLap = this.getBestLapTimeLap(lapsData)
        return bestLap
            && bestLap.timestamp == lapData.timestamp
            && bestLap.time == lapData.time
    }

    static getCurrentIsoTimeString(nowDate?: Date): string {
        const now = nowDate || new Date()
        return now.toISOString()
    }

    static getRemainingSeconds(
        sinceTimeStamp: string,
        durationHHMMSS: string,
        nowDate?: Date
    ): number {
        if (!sinceTimeStamp || !durationHHMMSS) return undefined
        const now = nowDate ?? new Date()
        const elapsedSeconds = TimeUtils.calcTimeInSeconds(now, sinceTimeStamp)
        let remainingSeconds = TimeUtils.durationToSeconds(durationHHMMSS) - elapsedSeconds
        if (remainingSeconds < 0) {
            remainingSeconds = 0
        }
        return remainingSeconds
    }

    static getFormattedRemainingTimeFromDuration(
        sinceTimeStamp: string,
        durationHHMMSS: string,
        nowDate?: Date
    ): string {
        if (!sinceTimeStamp || !durationHHMMSS) return undefined
        const now = nowDate ?? new Date()
        const remainingSeconds = TimeUtils.getRemainingSeconds(sinceTimeStamp, durationHHMMSS, now)
        return TimeUtils.formatDrivenTime(remainingSeconds, 1)
    }

    static formatDrivenTime(
        timeInSeconds: number,
        digits: number = 3,
        speakerLanguage?: LanguageType
    ): string {
        if (timeInSeconds == undefined
            || timeInSeconds < 0
            || isNaN(timeInSeconds)) {
            return "–"
        }
        if (timeInSeconds == 0) {
            return speakerLanguage
                ? "0.000"
                : CharUtils.replaceZeroWithO("0.000")
        }
        let minutes = Math.floor(timeInSeconds / 60)
        const hours = Math.floor(minutes / 60)
        minutes = minutes - (hours * 60)
        const secs = timeInSeconds % 60
        const formattedSeconds = this.formatSeconds(secs, digits, speakerLanguage)
        if (hours == 0) {
            if (minutes == 0) {
                if (speakerLanguage) {
                    return (formattedSeconds ?? "0") + (secs == 1 ? "{SEC}" : "{SECS}")
                }
                return CharUtils.replaceZeroWithO(formattedSeconds)
            } else {
                if (speakerLanguage) {
                    return minutes + " " + (minutes == 1 ? "{MIN}" : "{MINS}")
                        + " " + (formattedSeconds ?? "0") + (secs == 1 ? "{SEC}" : "{SECS}")
                }
                return CharUtils.replaceZeroWithO(minutes + ":" + (secs < 10 ? "0" : "") + formattedSeconds)
            }
        }
        if (speakerLanguage) {
            return hours + " " + (hours == 1 ? "{HOUR}" : "{HOURS}")
                + " " + minutes + " " + (minutes == 1 ? "{MIN}" : "{MINS}")
                + " " + (secs < 10 ? "0" : "") + (formattedSeconds ?? "0") + (secs == 1 ? "{SEC}" : "{SECS}")
        }
        return CharUtils.replaceZeroWithO(hours +
            ":" + (minutes < 10 ? "0" : "") + minutes +
            ":" + (secs < 10 ? "0" : "") + formattedSeconds)
    }

    static formatSeconds(secs: number, digits: number = 3, speakerLanguage?: LanguageType): string {
        if (secs == null) return undefined
        return secs.toLocaleString(
            speakerLanguage == "de" ? 'de-DE' : 'en-US',
            {style: "decimal", minimumFractionDigits: digits, maximumFractionDigits: digits}
        )
    }

    static formatDate(timestamp: string | number, showSeconds: boolean = false, showTime: boolean = true) {
        if (!timestamp) return undefined
        const date = new Date(timestamp);
        const options: Intl.DateTimeFormatOptions = {
            timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
        };
        if (showTime) {
            options.hour = '2-digit'
            options.minute = '2-digit'
        }
        if (showSeconds) {
            options.second = '2-digit'
            options.fractionalSecondDigits = 3
        }
        return date.toLocaleString(undefined, options);
    }

    static isBetweenNow(startTimestamp: string, endTimestamp: string, nowDate?: Date): boolean {
        return TimeUtils.isPast(startTimestamp, nowDate)
            && TimeUtils.isFuture(endTimestamp, nowDate)
    }

    static isToday(timestamp?: string, nowDate?: Date): boolean {
        if (!timestamp) return false
        const now = nowDate ?? new Date()
        const requestedDate = new Date(timestamp)
        if (!now || !requestedDate) return false
        return now.getDate() == requestedDate.getDate() && now.getMonth() == requestedDate.getMonth() && now.getFullYear() == requestedDate.getFullYear();
    }

    static isFuture(timestamp: string, nowDate?: Date): boolean {
        if (!timestamp) return true
        const requestedDate = new Date(timestamp)
        const now = nowDate ?? new Date()
        return now <= requestedDate
    }

    static isPast(timestamp: string, nowDate?: Date): boolean {
        if (!timestamp) return true
        const requestedDate = new Date(timestamp)
        const now = nowDate ?? new Date()
        return now >= requestedDate
    }

    static isSameDate(timestampA: string, timestampB: string): boolean {
        if (!timestampA || !timestampB) return false
        const dateA = new Date(timestampA)
        const dataB = new Date(timestampB)
        return dateA.getTime() === dataB.getTime()
    }

    static getDaysBetween(timestamp: string, nowDate?: Date): number {
        const now = nowDate ?? new Date()
        const then = new Date(timestamp);
        const diffInMilliseconds = Math.abs(now.getTime() - then.getTime());
        const diffInDays = diffInMilliseconds / 86400000;
        return Math.floor(diffInDays);
    }

    static getHoursBetween(timestamp: string, nowDate?: Date): number {
        const now = nowDate ?? new Date()
        const then = new Date(timestamp);
        const diffInMilliseconds = Math.abs(now.getTime() - then.getTime());
        const diffInHours = diffInMilliseconds / 3600000;
        return Math.floor(diffInHours);
    }

    static getMedianLapTime(lapTimes: number[]): number {
        if (!lapTimes || lapTimes.length == 0) return null
        const laps = [...lapTimes]
        laps.sort((timeA, timeB) => timeA - timeB);
        const middleIndex = Math.floor(laps.length / 2);
        if (laps.length % 2 === 0) {
            return (laps[middleIndex - 1] + laps[middleIndex]) / 2;
        }
        return laps[middleIndex];
    }
}
