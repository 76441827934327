import * as React from "react";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {InfoText} from "../../../text/infotext/InfoText";
import {useServices} from "../../../../hooks/useServices";
import {Icon} from "../../../icons/Icon";
import {IArticleBlockData} from "../../../../../../shared/models/IArticleData";
import {ArticleUtils} from "../../utils/ArticleUtils";

/******************************************************************
 * ArticleUnsupportedBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleUnsupportedBlock(props: {
    block: IArticleBlockData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            framed={true}
            columnTemplate="min-content auto"
            className="article-unsupported-block"
            alignItems="center">
            <Icon type="warning" iconColor="yellow"/>
            <InfoText text={dict("article.block.type.unsupported")
                    .replace("{BLOCKTYPE}", ArticleUtils.getBlockType(props.block))}/>
        </ContentLayout>
    );

}
